
<template>
  <RouterView :key="routes"/>
</template>
<script>
  // import { mapActions, mapState } from "vuex";
  export default {    
    name: 'App',
    data(){
      return{
         routes: ''
      }
    },
    computed:{
    },
    methods:{      
    },
    mounted(){
        this.routes = this.$route?.fullPath
    }               
  }
</script>

