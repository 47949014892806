import { createApp } from 'vue'
import { createHead } from "@vueuse/head";
import App from './App.vue'
import { store } from "./store";
import VueGtag from "vue-gtag";
import VueCookies from 'vue-cookies';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import './assets/css/font.css'
import './assets/css/border.css'
import './assets/css/button.css'
import './assets/css/input.css'
import './assets/css/margin-padding.css'
import './assets/css/table.css'
import './assets/css/cards.css'
import './assets/css/popup.css'
import './assets/css/date.css'
import './assets/css/component/components.css'
import "toastify-js/src/toastify.css"
import "@vueform/slider/themes/default.css"
import './assets/css/component/video.css'
import './assets/css/responsive.css'
import { registerModules } from "./register-modules";
import dedumo from "./modules/dedumo";
import admin from "./modules/admin";
import router from './router'
const GOOGLE_ANALYTICS_ID = process.env.VUE_APP_GOOGLE_ANALYTICS_ID;

registerModules({
    admin: admin,
    dedumo: dedumo
});

const app = createApp(App)
const head = createHead();
app.use(head);
app.use(VueGtag, {
    config: { id: GOOGLE_ANALYTICS_ID }
})
app.use(router)
app.use(store)
app.use(VueCookies)
app.mount('#app')