const Module = () => import("./Module.vue");
import NotFound from "@/components/NotFound.vue";
const moduleRoute = {
    path: '/',
    name: 'Dedumo',
    meta: {},
    component: Module,
    children: [{
        path: '',
        name: 'DedumoPage',
        meta: { },
        component: () =>
            import('./views/Dedumo.vue'),
        },
        { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
    ],
    
}
export default router => {
    router.addRoute(moduleRoute);
};